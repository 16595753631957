/**
 * Determines if a URL points to an image or video based on its file extension.
 *
 * @param url - The URL string to check.
 * @returns A string indicating whether the URL contains "image", "video", or "unknown".
 */
export function checkLinkType(url: string): "image" | "video" | "unknown" {
  const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp", "webp", "svg"];
  const videoExtensions = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm"];

  try {
    const extension = url.split(".").pop()?.toLowerCase();

    if (!extension) {
      return "unknown";
    }

    if (imageExtensions.includes(extension)) {
      return "image";
    }

    if (videoExtensions.includes(extension)) {
      return "video";
    }
  } catch (error) {
    console.error("Error checking URL:", error);
  }

  return "unknown";
}
