import apiSlice from "../../features/apiSlice";
import { removeEmptyKeys } from "../../utils/helper";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getSettings: builder.query({
      query: (queries) => {
        return {
          url: '/settings',
          params: removeEmptyKeys(queries),
        };
      },
      providesTags:['Settings']
    }),
    createSettings: builder.mutation({
      query: (credentials) => ({
        url: "/settings",
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Settings']
    }),
    updateSettings: builder.mutation({
      query: (credentials) => ({
        url: `/settings/${credentials.id}`,
        method: "PUT",
        body: credentials.body,
      }),
      invalidatesTags:['Settings']
    }),
    bulkUpdateSettings: builder.mutation({
      query: (credentials) => ({
        url: `/settings/updateMany`,
        method: "POST",
        body: credentials,
      }),
      invalidatesTags:['Settings']
    }),
    deleteSettings: builder.mutation({
      query: (credentials) => ({
        url: `/settings/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Settings']
    }),
    hardDeleteSettings: builder.mutation({
      query: (credentials) => ({
        url: `/settings/hardDelete/${credentials.id}`,
        method: "DELETE",
        body: credentials.body,
      }),
      invalidatesTags:['Settings']
    }),
  }),
});

export const {
  useGetSettingsQuery,
  useCreateSettingsMutation,
  useUpdateSettingsMutation,
  useBulkUpdateSettingsMutation,
  useDeleteSettingsMutation,
  useHardDeleteSettingsMutation
} = authApiSlice;
