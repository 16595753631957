import { createBrowserRouter } from "react-router-dom";
import AuthDashboard from "../pages/auth/auth";
import Dashboard from "../pages/dashboard";
import Products from "../pages/dashboard/products";
import OrderPage from "../pages/dashboard/orders";
// import ReturnPage from "../pages/dashboard/returns";
import BrandPage from "../pages/dashboard/brand";
import CategoryPage from "../pages/dashboard/category";
import TypesPage from "../pages/dashboard/types";
import UsersPage from "../pages/dashboard/users";
import ManufacturerPage from "../pages/dashboard/manufacturer";
import SettingsPage from "../pages/dashboard/settings.tsx";
import Revenue from "../pages/dashboard/revenue";


const Router = () => {
  return createBrowserRouter([
    {
      path: "/",
      element: <AuthDashboard />,
    },
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/products",
      element: <Products />,
    },
    {
      path: "/orders",
      element: <OrderPage />,
    },
    {
      path: "/revenue",
      element: <Revenue />,
    },
    {
      path: "/users",
      element: <UsersPage />,
    },
    {
      path: "/brand",
      element: <BrandPage />,
    },
    {
      path: "/category",
      element: <CategoryPage />,
    },
    {
      path: "/types",
      element: <TypesPage />,
    },
    {
      path: "/manufacturer",
      element: <ManufacturerPage />,
    },
    {
      path: "/settings",
      element: <SettingsPage />,
    },
  ]);
};

export default Router;
