import { Ballot, Category, Class, Dashboard, Interests, LocalMall, People, PrecisionManufacturing, Refresh, Tune,Money, Settings } from "@mui/icons-material";
import { ButtonV1 } from "../atoms/button";
import { FilterTabV2 } from "./filterTab";
import { getAuthFromLocal, removeAuthFromLocal } from "../../utils/storage";
import { useNavigate } from "react-router-dom";
import { authDetails } from "../../api/auth/authActions";

export function SidebarAdmin() {
  let data: authDetails = getAuthFromLocal();
  const navigate = useNavigate();
  function logoutHandler() {
    removeAuthFromLocal();
    navigate("/");
  }

  !data && logoutHandler();

  return (
    <div className="sticky top-[10vh] w-[20vw] max-[800px]:w-[30vw] h-[90vh] bg-white px-[2vw] py-[2vh] flex flex-col shadow-lg justify-between">
      <div className="h-[90%] relative">
        <div className="overflow-y-auto h-[100%] scrollerRed">
          <FilterTabV2 href="/dashboard" icon={<Dashboard  style={{ fontSize: "20px", marginLeft: "10px" }}/>} > Dashboard </FilterTabV2>
          <FilterTabV2 href="/products" icon={<Interests  style={{ fontSize: "20px", marginLeft: "10px" }}/>}> All  Products </FilterTabV2>
          <FilterTabV2 href="/brand" icon={<Ballot  style={{ fontSize: "20px", marginLeft: "10px" }}/>}> Brand </FilterTabV2>
          <FilterTabV2 href="/types" icon={<Class  style={{ fontSize: "20px", marginLeft: "10px" }}/>}> Types </FilterTabV2>
          <FilterTabV2 href="/category" icon={<Category  style={{ fontSize: "20px", marginLeft: "10px" }}/>}> Category </FilterTabV2>
          <FilterTabV2 href="/manufacturer" icon={<PrecisionManufacturing  style={{ fontSize: "20px", marginLeft: "10px" }}/>}> Manufacturer </FilterTabV2>
          <FilterTabV2 href="/orders" icon={<LocalMall  style={{ fontSize: "20px", marginLeft: "10px" }}/>}> Order List </FilterTabV2>
          <FilterTabV2 href="/revenue" icon={<Money  style={{ fontSize: "20px", marginLeft: "10px" }}/>}> Revenue </FilterTabV2>
          {
            data?.roles === "admin" &&
            <FilterTabV2 href="/users" icon={<People  style={{ fontSize: "20px", marginLeft: "10px" }}/>}> Customers </FilterTabV2>
          }
          {
            data?.roles === "admin" &&
            <FilterTabV2 href="/settings" icon={<Settings  style={{ fontSize: "20px", marginLeft: "10px" }}/>}> Settings </FilterTabV2>
          }
        </div>
      </div>
      <ButtonV1 color="red" setActive={() => logoutHandler()}>
        log out
      </ButtonV1>
    </div>
  );
}
