import { Autocomplete, TextField } from "@mui/material";
import { FormikValues } from "formik";
import { SyntheticEvent, useEffect, useState } from "react";

interface props {
  lable: string;
  name?: string;
  options: Option[];
  value:
    | {
        id: string;
        title: string;
      }
    | undefined;
  handleChange: Function;
  touched?: string;
  errors?: string;
}

interface propsV2 {
  value?: string;
  errors?: string;
  touched?: string;
  lable: string;
  name: string;
  options: {
    id: string;
    title: string;
  }[];
  formik?: FormikValues;

}

interface Option {
  id: string;
  title: string;
}

export function SelectBox({
  lable,
  options,
  name,
  handleChange,
  value,
}: props) {
  const [optionList, setOptionList] = useState([
    {
      id: "1234567890",
      title: "Types",
    },
  ]);

  const handleSelectChange = (
    event: SyntheticEvent<Element, Event>,
    value: Option | null
  ) => {
    handleChange(value);
  };

  useEffect(() => {
    if (options) {
      setOptionList(options);
    }
  }, [options]);

  return (
    <div className="flex flex-col gap-1 mb-4">
      {lable && (
        <label className="font-semibold text-sm capitalize">{lable}</label>
      )}
      <Autocomplete
        componentName={name}
        limitTags={2}
        id="multiple-limit-tags"
        options={optionList}
        getOptionLabel={(option) => option?.title}
        defaultValue={
          options?.filter((item) =>
            value && value.id === item.id ? item : false
          )[0]
        }
        renderInput={(params) => (
          <TextField
            name={name}
            {...params}
            label={lable}
            placeholder={lable}
          />
        )}
        sx={{ width: "500px" }}
        className="rounded-xl capitalize mt-1"
        onChange={handleSelectChange}
      />
      {!value && <p className="text-[#F45B69]">Required</p>}
    </div>
  );
}

export function SelectBoxV1({
  lable,
  options,
  formik,
  name,
  value,
  touched,
  errors,
}: propsV2) {
  const [optionList, setOptionList] = useState([
    {
      id: "1234567890",
      title: "Types",
    },
  ]);
  const handleSelectChange = (
    event: SyntheticEvent<Element, Event>,
    value: Option | null
  ) => {
    console.log("selectedValues", value);
    // console.log(value.map((item) => item.id));
    formik && formik.setFieldValue(`${name}`, value && value.id);
  };

  useEffect(() => {
    if (options) {
      setOptionList(options);
    }
  }, [options]);

  return (
    <div className="flex flex-col gap-1 mb-4 w-[47%]">
      {lable && (
        <label className="font-semibold text-sm capitalize">{lable}</label>
      )}
      <Autocomplete
        limitTags={2}
        id="multiple-limit-tags"
        options={optionList}
        getOptionLabel={(option) => option?.title}
        value={options?.filter((item) => value?.includes(item.id) && item)[0]}
        renderInput={(params) => (
          <TextField {...params} label={lable} placeholder={lable} />
        )}
        sx={{ width: "500px" }}
        className="rounded-xl capitalize mt-1"
        onChange={handleSelectChange}
      />
      {(touched || errors) && <p className="text-[#F45B69]">{errors}</p>}
    </div>
  );
}
