import { useEffect, useState } from "react";
import { ButtonV1, ButtonV2 } from "../../components/atoms/button";
import { InputFieldV1 } from "../../components/atoms/inputField";
import { useNavigate } from "react-router-dom";
import {
  useLoginMutation,
  useRegisterMutation,
  useVerifyOtpMutation,
} from "../../api/auth/authApiSlice";
import { useFormik } from "formik";
import {
  LoginSchema,
  RegisterSchema,
  VerifyOtpSchema,
} from "../../validationSchema/profileSchema";
import snackbar from "../../api/snackbar/useSnackbar";
import { useDispatch } from "react-redux";
import { authDetails, loginHandler } from "../../api/auth/authActions";
import { getAuthFromLocal } from "../../utils/storage";
import OtpVerification from "../../components/molecules/otpVerification/OtpVerification";

function AuthDashboard() {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const [active, setActive] = useState("login");

  let data: authDetails = getAuthFromLocal();

  useEffect(() => {
    data && navigate("/dashboard");
  }, [data]);

  const [
    login,
    {
      data: loginData,
      isLoading: loginIsLoading,
      isSuccess: loginIsSuccess,
      error: loginIsError,
    },
  ] = useLoginMutation();

  const [
    verify,
    {
      data: verifyData,
      isLoading: verifyIsLoading,
      isSuccess: verifyIsSuccess,
      error: verifyIsError,
    },
  ] = useVerifyOtpMutation();

  const [
    register,
    {
      data: registerData,
      isLoading: registerIsLoading,
      isSuccess: registerIsSuccess,
      error: registerIsError,
    },
  ] = useRegisterMutation();

  const Login = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    enableReinitialize: true,
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      login(values)
        .unwrap()
        .then((res: { data: any; message: string }) => {
          console.log(res);
          let authDetails = {
            accessToken: res.data.authToken,
            userId: res.data.user._id,
            email: res.data.user.email,
            fullName: res.data.user.fullName,
            active: res.data.user.active,
            isEmailVerified: res.data.user.isEmailVerified,
            registrationStatus: res.data.user.registrationStatus,
            roles: res.data.user.roles[0],
          };
          dispatch(loginHandler(authDetails));
          snackbar.success(res.message);
          navigate("/dashboard");
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  const Register = useFormik({
    initialValues: {
      email: "",
      password: "",
      fullName: "",
      confirmPassword: "",
      mode: "email",
    },
    enableReinitialize: true,
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      register(values)
        .unwrap()
        .then((res: { data: any; message: string }) => {
          console.log(res);
          snackbar.success(res.message);
          setActive("otp");
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  const VerifyOtp = useFormik({
    initialValues: {
      mode: "email",
      purpose: "registration",
      email: Register.values.email || "",
      code: "",
    },
    enableReinitialize: true,
    validationSchema: VerifyOtpSchema,
    onSubmit: (values) => {
      verify(values)
        .unwrap()
        .then((res: { data: any; message: string }) => {
          console.log(res);
          let authDetails = {
            accessToken: res.data.token,
            userId: res.data.user._id,
            email: res.data.user.email,
            fullName: res.data.user.fullName,
            active: res.data.user.active,
            isEmailVerified: res.data.user.isEmailVerified,
            registrationStatus: res.data.user.registrationStatus,
            roles: res.data.user.roles[0],
          };
          dispatch(loginHandler(authDetails));
          snackbar.success(res.message);
          setActive("login");
          navigate("/dashboard");
        })
        .catch((error: { data: { message: string } }) => {
          console.log(error);
          snackbar.error(error?.data?.message);
        });
    },
  });

  return (
    <div className="relative w-screen h-screen overflow-hidden flex justify-center items-center">
      <div
        className={`bg-white w-1/4 max-h-4/6 h-auto min-w-[20rem] md:min-w-[25rem] min-[1000px]:min-w-[25rem] overflow-y-scroll border-t rounded-xl shadow-lg shadow-slate-300 px-[2.5vw] py-[5vh] flex justify-between items-center flex-col effect
        heightTran`}
      >
        <img
          src="/img/logo.png"
          alt="logo"
          className="h-auto w-1/6 min-w-[10rem] mb-8 rounded-full"
        />
        {active === "login" ? (
          <form
            noValidate
            onSubmit={Login.handleSubmit}
            className={`w-full flex flex-col gap-1 fadeEffect heightTran ${
              active === "login" ? "opacity-100 expanded" : "opacity-0"
            } `}
          >
            <InputFieldV1
              name="email"
              lable="Email"
              type="email"
              placeholder="Email"
              value={Login.values.email}
              errors={Login.errors.email}
              handleChange={Login.handleChange}
              handleBlur={Login.handleBlur}
            />
            <InputFieldV1
              name="password"
              lable="Password"
              type="password"
              placeholder="Password"
              value={Login.values.password}
              errors={Login.errors.password}
              handleChange={Login.handleChange}
              handleBlur={Login.handleBlur}
            />
            <ButtonV1 type="submit" color="blue">
              Login
            </ButtonV1>
            {/* <ButtonV3 color="blue">forget password ?</ButtonV3> */}
            <ButtonV2 color="blue" setActive={() => setActive("register")}>
              Register
            </ButtonV2>
          </form>
        ) : active === "register" ? (
          <form
            noValidate
            onSubmit={Register.handleSubmit}
            className={`w-full flex flex-col gap-1 fadeEffect heightTran ${
              active === "register" ? "opacity-100 expanded" : "opacity-0"
            } `}
          >
            <InputFieldV1
              name="fullName"
              lable="Name"
              type="text"
              placeholder="eg: John"
              value={Register.values.fullName}
              errors={Register.errors.fullName}
              handleChange={Register.handleChange}
              handleBlur={Register.handleBlur}
            />
            <InputFieldV1
              name="email"
              lable="Email"
              type="email"
              placeholder="eg: abc@mail.com"
              value={Register.values.email}
              errors={Register.errors.email}
              handleChange={Register.handleChange}
              handleBlur={Register.handleBlur}
            />
            <InputFieldV1
              name="password"
              lable="Password"
              type="password"
              placeholder="Password"
              value={Register.values.password}
              errors={Register.errors.password}
              handleChange={Register.handleChange}
              handleBlur={Register.handleBlur}
            />
            <InputFieldV1
              name="confirmPassword"
              lable="Confirm Password"
              type="text"
              placeholder="Confirm Password"
              value={Register.values.confirmPassword}
              errors={Register.errors.confirmPassword}
              handleChange={Register.handleChange}
              handleBlur={Register.handleBlur}
            />
            <ButtonV1 color="blue" type="submit">
              Register
            </ButtonV1>
            <ButtonV2 color="blue" setActive={() => setActive("login")}>
              Login
            </ButtonV2>
          </form>
        ) : (
          <form
            noValidate
            onSubmit={VerifyOtp.handleSubmit}
            className={`w-full flex flex-col gap-1 fadeEffect heightTran ${
              active === "otp" ? "opacity-100 expanded" : "opacity-0"
            } `}
          >
            <InputFieldV1
              name="email"
              lable="Email"
              type="email"
              placeholder="Registered email"
              value={VerifyOtp.values.email}
              errors={VerifyOtp.errors.email}
              handleChange={VerifyOtp.handleChange}
              handleBlur={VerifyOtp.handleBlur}
            />
            <OtpVerification formik={VerifyOtp} name={"code"} />
            <ButtonV1 color="blue" type="submit">
              Login
            </ButtonV1>
          </form>
        )}
      </div>
    </div>
  );
}

export default AuthDashboard;
