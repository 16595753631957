import AdminLayout from "../../components/organisms/adminLayout";
import DataGridBox from "../../components/organisms/dataGrid";
import {
  GridActionsCellItem,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  GridRowModesModel,
  GridRowParams,
  GridRowsProp,
  GridTreeNodeWithRender,
} from "@mui/x-data-grid";
import React, { ChangeEvent, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import AdminPageFilters from "../../components/organisms/adminPageFilters";
import {
  useBulkUpdateOrderMutation,
  useUpdateStatusOrderMutation,
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useGetOrderQuery,
  useUpdateOrderMutation,
} from "../../api/order";
import { DeletePopup } from "../../components/molecules/DeleteModal";
import snackbar from "../../api/snackbar/useSnackbar";
import { VisibilityOutlined } from "@mui/icons-material";
import { OrderModal } from "../../components/molecules/OrderModal";
import { OrderType } from "../../types/types";
import { Box, Tab, Tabs } from "@mui/material";

interface Item {
  _id: string;
  srNo: string;
  status: string;
  orderStatus: string;
  orderID: string;
  paymentStatus: string;
  customer: {
    countryCode: string;
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
  };
  shippingStatus?: string;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function OrderPage() {
  const [rows, setRows] = React.useState<GridRowsProp>([]);
  const [isDelete, setDelete] = React.useState(false);
  const [isEdit, setEdit] = React.useState(false);
  const [isAddNew, setAddNew] = React.useState(false);
  const [Order, setOrder] = React.useState("");
  const [regex, setRegex] = React.useState<string>("");
  const [sortType, setSortType] = React.useState<string>("createdAt");
  const [updateType, setUpdateType] = React.useState<string>("createdAt");
  const [sortOrder, setSortOrder] = React.useState<Number>(-1);
  const [currentId, setCurrentId] = React.useState<GridRowId>("");
  const [currentItem, setCurrentItem] = React.useState<OrderType>();
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>();
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 25,
    page: 0,
  });
  const [tabValue, setTabValue] = useState<number>(0);
  const [selectedRowIds, setSelectedRowIds] = React.useState<GridRowId[]>([]);
  const [selectDate, setSelectDate] = React.useState<any>({
    fromDate: "",
    toDate: "",
  });

  const {
    data: OrderData,
    isFetching: OrderIsFetching,
    isSuccess: OrderSuccess,
    refetch: orderRefetch,
  } = useGetOrderQuery({
    orderID: regex,
    pageSize: paginationModel.pageSize,
    pageNo: paginationModel.page + 1,
    sortType: sortType,
    sortOrder: -1,
    fromDate: selectDate.fromDate,
    toDate: selectDate.toDate,
    shippingStatus: tabValue === 1 ? "print" : tabValue === 2 ? "handover" : "",
  });

  const [update, { isLoading: updateIsLoading, isSuccess: updateSuccess }] =
    useUpdateOrderMutation({});

  const [
    updateStatus,
    { isLoading: updateStatusIsLoading, isSuccess: updateStatusSuccess },
  ] = useUpdateStatusOrderMutation({});

  const [
    bulkUpdateData,
    { isLoading: bulkUpdateIsLoading, isSuccess: bulkUpdateSuccess },
  ] = useBulkUpdateOrderMutation({});

  const [create, { isLoading: createIsLoading, isSuccess: createSuccess }] =
    useCreateOrderMutation({});

  const [
    deleteOrder,
    { isLoading: deleteIsLoading, isSuccess: deleteSuccess },
  ] = useDeleteOrderMutation({});

  const columns: GridColDef[] = [
    { field: "srNo", headerName: "srNo", width: 100, editable: false },
    { field: "orderID", headerName: "OrderId", width: 150, editable: false },
    {
      field: "customerName",
      headerName: "Customer",
      width: 150,
      editable: false,
    },
    {
      field: !tabValue ? "status" : "shippingStatus",
      headerName: !tabValue ? "Status" : "Shipping status",
      width: 150,
      renderCell: (
        params: GridRenderCellParams<any, any, any, GridTreeNodeWithRender>
      ) =>
        tabValue === 0 ? (
          <select
            value={params.row.status}
            onChange={(e) => handleStatusChange(e.target.value, params.row.id)}
          >
            <option value={"confirmed"}>Confirmed</option>
            <option value={"dispatched"}>Dispatched</option>
            <option value={"delivered"}>Delivered</option>
            {/* <option value={"returned"}>Returned</option> */}
          </select>
        ) : (
          <span className="capitalize">{params.row.shippingStatus}</span>
        ),
    },
    // {
    //   field: "paymentStatus",
    //   headerName: "Payment Status",
    //   width: 150,
    //   editable: false,
    // },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 180,
      cellClassName: "actions",
      getActions: ({ id }: GridRowParams<any>) => {
        if (tabValue === 0) {
          return [
            <GridActionsCellItem
              key={`edit-${id}`}
              icon={<VisibilityOutlined className="text-blue-400" />}
              label="Edit"
              className="textPrimary"
              onClick={handleEditClick(id)}
              color="inherit"
            />,
            <GridActionsCellItem
              key={`delete-${id}`}
              icon={<DeleteIcon className="text-red-400" />}
              label="Delete"
              onClick={handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        }
        if (tabValue === 1) {
          return [
            <button
              key={`handover-${id}`}
              onClick={() => handleShippingStatusChange("handover", id)}
              className="py-2 px-4 rounded bg-blue-500 text-white"
            >
              Handover
            </button>,
          ];
        }
        return [];
      },
    },
  ];

  useEffect(() => {
    if (OrderSuccess && OrderData) {
      console.log(OrderData?.data?.data);
      setRows(
        OrderData?.data?.data.map((item: Item) => ({
          id: item?._id,
          status: item?.orderStatus,
          customerName: item?.customer?.firstName,
          paymentStatus: item?.paymentStatus,
          orderID: item?.orderID,
          srNo: item?.srNo,
          shippingStatus: item?.shippingStatus,
        }))
      );
    }
  }, [OrderData, OrderIsFetching, OrderSuccess]);

  const handleCreateClick = () => {
    setAddNew(true);
  };

  const handleStatusChange = (status: string, id: GridRowId) => {
    console.log("id Status", id, status);
    updateStatus({ orderId: id, orderStatus: status })
      .unwrap()
      .then((res: { message: string }) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setAddNew(false);
      })
      .catch((error: { data: { message: string } }) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  };

  const handleShippingStatusChange = (status: string, id: GridRowId) => {
    console.log("id Status", id, status);
    updateStatus({ orderId: id, shippingStatus: status })
      .unwrap()
      .then((res: { message: string }) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setAddNew(false);
      })
      .catch((error: { data: { message: string } }) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  };

  const handleEditClick = (id: GridRowId) => () => {
    setEdit(true);
    setCurrentItem(
      OrderData?.data?.data?.filter((item: OrderType) => item._id === id)[0]
    );
    console.log(id);
  };

  const handleDeleteClick = (id: GridRowId) => () => {
    setDelete(true);
    setCurrentId(id);
    console.log(id);
  };

  const handleSelectionChange = (newSelection: GridRowId[]) => {
    console.log(newSelection);
    setSelectedRowIds(newSelection);
  };

  function handleCreate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    create({ name: Order })
      .unwrap()
      .then((res: { message: string }) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setAddNew(false);
      })
      .catch((error: { data: { message: string } }) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleUpdate(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    update({ id: currentId, body: { name: Order } })
      .unwrap()
      .then((res: { message: string }) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setEdit(false);
      })
      .catch((error: { data: { message: string } }) => {
        console.log(error);
        snackbar.error(error?.data?.message);
      });
  }

  function handleDelete() {
    deleteOrder({ id: currentId })
      .unwrap()
      .then((res: { message: string }) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setDelete(false);
      })
      .catch((error: { data: { message: string } }) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setDelete(false);
      });
  }

  function bulkUpdate() {
    console.log(updateType);

    let data;
    if (updateType === "delete") {
      data = { active: false };
    }
    bulkUpdateData({ id: selectedRowIds, data })
      .unwrap()
      .then((res: { message: string }) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setUpdateType("");
      })
      .catch((error: { data: { message: string } }) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  function bulkUpdateStatus(data: string) {
    bulkUpdateData({ id: selectedRowIds, data: { shippingStatus: data } })
      .unwrap()
      .then((res: { message: string }) => {
        console.log(res);
        snackbar.success(res?.message || "Success");
        setUpdateType("");
      })
      .catch((error: { data: { message: string } }) => {
        console.log(error);
        snackbar.error(error?.data?.message);
        setUpdateType("");
      });
  }

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  useEffect(() => {
    if (tabValue) {
      orderRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabValue]);

  console.log("selectedRowIds>>>>", selectedRowIds);

  return (
    <AdminLayout>
      <AdminPageFilters
        name="Orders"
        placeholder="ORDER-ID"
        qty={OrderData?.data?.totalCount}
        setAddNew={handleCreateClick}
        setRegex={setRegex}
        bulkUpdate={bulkUpdate}
        bulkUpdateStatus={bulkUpdateStatus}
        setUpdateType={setUpdateType}
        setSortType={setSortType}
        regex={regex}
        selectedRowIds={selectedRowIds}
        tabValue={tabValue}
        selectDate={selectDate}
        setSelectDate={setSelectDate}
      />
      <Tabs
        value={tabValue}
        onChange={handleChange}
        aria-label="basic tabs example"
      >
        <Tab label="All" {...a11yProps(0)} />
        <Tab label="Print" {...a11yProps(1)} />
        <Tab label="Handover" {...a11yProps(2)} />
      </Tabs>
      <CustomTabPanel value={tabValue} index={0}>
        <DataGridBox
          qty={OrderData?.data?.totalCount}
          checkboxSelection={true}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={columns}
          initialRows={rows}
          rowModesModel={rowModesModel}
          selectedRowIds={selectedRowIds}
          setRowModesModel={setRowModesModel}
          onSelectionModelChange={handleSelectionChange}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={1}>
        <DataGridBox
          qty={OrderData?.data?.totalCount}
          checkboxSelection={true}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={columns}
          initialRows={rows}
          rowModesModel={rowModesModel}
          selectedRowIds={selectedRowIds}
          setRowModesModel={setRowModesModel}
          onSelectionModelChange={handleSelectionChange}
        />
      </CustomTabPanel>
      <CustomTabPanel value={tabValue} index={2}>
        <DataGridBox
          qty={OrderData?.data?.totalCount}
          checkboxSelection={true}
          paginationModel={paginationModel}
          setPaginationModel={setPaginationModel}
          columns={columns}
          initialRows={rows}
          rowModesModel={rowModesModel}
          selectedRowIds={selectedRowIds}
          setRowModesModel={setRowModesModel}
          onSelectionModelChange={handleSelectionChange}
        />
      </CustomTabPanel>

      {isDelete && (
        <DeletePopup
          open={isDelete}
          handleClose={() => setDelete(false)}
          handleDelete={handleDelete}
        />
      )}
      {isEdit && (
        <OrderModal
          item={currentItem}
          open={isEdit}
          handleClose={() => setEdit(false)}
        />
      )}
    </AdminLayout>
  );
}

export default OrderPage;
