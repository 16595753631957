import { RouterProvider } from "react-router-dom";
import "./App.css";
import Router from "./navigation/Routes";
import { AnimatePresence } from "framer-motion";
import { Provider } from "react-redux";
import { SnackbarProvider } from "notistack";
import { SnackbarUtilsConfigurator } from "./api/snackbar/useSnackbar";
import store from "./features/store";

import {
  SuccessMessage,
  ErrorMessage,
  WarningMessage,
  InfoMessage,
} from "./components/molecules/snackbar";

function App() {
  return (
    <AnimatePresence initial={false}>
      <Provider store={store}>
        <SnackbarProvider
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          Components={{
            success: SuccessMessage,
            error: ErrorMessage,
            warning: WarningMessage,
            info: InfoMessage,
          }}
          maxSnack={1}
          autoHideDuration={1000}
        >
          <SnackbarUtilsConfigurator />
          <RouterProvider router={Router()} />
        </SnackbarProvider>
      </Provider>
    </AnimatePresence>
  );
}

export default App;
