import AdminLayout from "../../components/organisms/adminLayout";
import React, { useEffect } from "react";
import {
    useGetSettingsQuery,
    useUpdateSettingsMutation,
} from "../../api/filters/Settings";
import snackbar from "../../api/snackbar/useSnackbar";
import { useFormik } from "formik";
import { IOSSwitch } from "../../components/atoms/SwitchInputField";

interface Item {
    _id: string;
    type: string;
    active: string;
}

function SettingsPage() {
    const [active, setActive] = React.useState(1);

    const {
        data: SettingsData,
        isSuccess: SettingsSuccess,
    } = useGetSettingsQuery({
        sortOrder: -1,
    });

    const [update, { isLoading: updateIsLoading }] =
        useUpdateSettingsMutation({});

    const Formik = useFormik({
        initialValues: {
            codPayment: SettingsData?.data[0]?.codPayment,
            onlinePayment: SettingsData?.data[0]?.onlinePayment,
            discount: SettingsData?.data[0]?.discount,
        },
        enableReinitialize: false,
        // validationSchema: ProductSchema,
        onSubmit: (values) => {
            if (updateIsLoading) return;
            update({
                id: SettingsData?.data[0]?._id,
                body: { ...values }
            })
                .unwrap()
                .then((res: { message: string }) => {
                    console.log(res);
                    snackbar.success(res.message);
                })
                .catch((error: { data: { message: string } }) => {
                    console.log(error);
                    snackbar.error(error?.data?.message);
                });
        },
    });

    useEffect(() => {
        if (!Formik.values?.codPayment) {
            Formik.setFieldValue("onlinePayment", true)
            Formik.setFieldValue("codPayment", false)
        }
    }, [Formik.values.codPayment])

    useEffect(() => {
        if (!Formik.values?.onlinePayment) {
            Formik.setFieldValue("codPayment", true)
            Formik.setFieldValue("onlinePayment", false)
        }
    }, [Formik.values.onlinePayment])

    useEffect(() => {
        if (Formik.values?.codPayment !== undefined && (Formik.values?.codPayment !== false || Formik.values?.onlinePayment !== false) && active > 3) {
            Formik.handleSubmit()
        }
        setActive(active + 1)
    }, [Formik.values])

    useEffect(() => {
        if (SettingsData?.data && SettingsSuccess) {
            Formik.setFieldValue("codPayment", SettingsData?.data[0]?.codPayment)
            Formik.setFieldValue("onlinePayment", SettingsData?.data[0]?.onlinePayment)
            Formik.setFieldValue("discount", SettingsData?.data[0]?.discount)
            setActive(active + 1)
        }
    }, [SettingsData])

    return (
        <AdminLayout>
            <div className="w-full">
                <div className="flex justify-between w-full pb-4 ">
                    <p className="text-[2rem] font-semibold flex flex-col capitalize">
                        Settings
                    </p>
                </div>
            </div>
            <form>
                <IOSSwitch
                    sx={{ m: 1 }}
                    aria-label="Cash on Delivery Mode"
                    checked={Formik.values.codPayment}
                    onChange={Formik.handleChange}
                    name="codPayment"
                />
                <IOSSwitch
                    sx={{ m: 1 }}
                    aria-label="Online payment Mode"
                    checked={Formik.values.onlinePayment}
                    onChange={Formik.handleChange}
                    name='onlinePayment'
                />
                <IOSSwitch
                    sx={{ m: 1 }}
                    aria-label="Discount on Online payment"
                    checked={Formik.values.discount}
                    onChange={Formik.handleChange}
                    name="discount"
                />
            </form>
        </AdminLayout>
    );
}

export default SettingsPage;
